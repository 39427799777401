<template>
  <div>
    <popup-pdf ref="popupPdf" @show="$emit('show')"/>
    <select-employee v-model="employees" :multiple="true" />
    <vs-divider />
     <div class="flex flex-row-reverse">
          <vs-button
            color="primary"
            type="filled"
            @click="showPdf"
            >Confirmar</vs-button
          >
        </div>
  </div>
</template>

<script>
import SelectEmployee from '@/components/register/employee/SelectEmployee'
import PopupPdf from '../pdf/PopupPdf.vue'
export default {
  components: {
    SelectEmployee,
    PopupPdf
  },
  props: {
    value: {}
  },
  data () {
    return {
      employees: null
    }
  },
  methods: {
    showPdf () {
      this.$refs.popupPdf.post({
        url: `${process.env.VUE_APP_API_URL}/report/financial/contract/employeeCommission`,
        format: this.type,
        payload: {
          ...this.value,
          employees: this.employees.map(e => e.id)
        }
      })
    }
  }
}
</script>

<style>

</style>
