<template>
  <div id="student-documents">
    <popup-pdf ref="popupPdf" :format="typeReport"/>
    <contract-employee-commission-modal :active.sync="contractEmployeeComissionPopup" v-model="params"/>
    <vs-popup
      class="popup80"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupShowPdf"
    >
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>
    <!-- FUNCIONARIO -->
    <sig-prompt
      :active="popupEmployee"
      @cancel="popupEmployee = false"
      @accept="() => {this.popupEmployee = false; this.generateReport(11)}"
      title="Serviço por funcionário"
    >
    <select-employee v-model="employee" />
   </sig-prompt>

    <!-- MEU CAIXA -->
    <vs-popup
      class="popup50"
      :title="individualCashier.title"
      :active.sync="popupIndividualCashier"
    >
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Responsável</label>
          <v-select
            class="vue_select_drop_size_150"
            v-model="individualCashier.user_id"
            :clearable="false"
            data-vv-as="Responsável"
            data-vv-scope="individualCashier"
            v-validate.initial="'required'"
            name="user_id"
            :options="users"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('individualCashier.user_id')"
            >Campo Obrigatório.</span
          >
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupIndividualCashier = false"
              >Cancelar</vs-button
            >
            <vs-button
              class="ml-4"
              @click="generateReport(7)"
              :disabled="!validateIndividualCashier"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- CONTRATOS REALOCADO PARA A ABA DE SERVIÇOS -->
    <!-- <vs-popup class="popup80" :fullscreen="false" title="Relatório de Contratos" :active.sync="popupContract">
      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <label class="vs-input--label">Serviços Contratados</label>
          <v-select class="vue_select_drop_size_90" v-model="contract.servicePackages"
            :options="servicePackages" multiple placeholder="TODOS" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Situação</label>
          <v-select class="vue_select_drop_size_90" v-model="contract.situation_contract"
            :options="[
              { value: 'IN_PROGRESS', label: 'Em Andamento'},
              { value: 'FINISHED',    label: 'Finalizado'},
              { value: 'CANCELED',    label: 'Cancelado'}
            ]" placeholder="TODAS" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupContract = false">Cancelar</vs-button>
            <vs-button class="ml-4" @click="generateReport(5)">Gerar Documento</vs-button>
          </div>
        </div>
      </div>
    </vs-popup> -->

    <!-- ESTORNO -->
    <vs-popup
      class="popup80"
      :fullscreen="false"
      title="Relatório de Estornos"
      :active.sync="popupChargeback"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Plano de Contas</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="chargeback.account_plan_id"
            :options="accountPlans"
            placeholder="TODOS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="chargeback.type_payment_id"
            :options="typePayments"
            placeholder="TODOS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Entrada/Saída</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="chargeback.income_expense"
            :options="[
              { value: 'CREDIT', label: 'Entradas' },
              { value: 'DEBIT', label: 'Saídas' },
            ]"
            placeholder="TODOS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Serviços Contratados</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="chargeback.servicePackages"
            :options="servicePackages"
            multiple
            placeholder="TODOS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupChargeback = false"
              >Cancelar</vs-button
            >
            <vs-button class="ml-4" @click="generateReport(6)"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- PERSONALIZÁVEL -->
    <vs-popup
      class="popup80"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupCustomizable"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full">
          <vs-input
            class="w-full"
            v-model="customizable.title"
            label="Título"
          />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Data</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="customizable.for_date"
            :reduce="(option) => option.value"
            :clearable="false"
            :options="[
              { label: 'Data de Vencimento', value: 'due_date' },
              { label: 'Data de Pagamento', value: 'payment_date' },
              { label: 'Data de Lançamento', value: 'release_date' },
            ]"
            placeholder="TODOS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Situação</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="customizable.situation"
            :clearable="true"
            :reduce="(option) => option.value"
            :options="[
              { label: 'Pagos', value: 'PAID' },
              { label: 'Não pago', value: 'PENDING' },
            ]"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Contas</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="customizable.accounts"
            multiple
            :reduce="(option) => option.value"
            :options="accounts"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Planos de Conta</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="customizable.accountPlans"
            multiple
            :reduce="(option) => option.value"
            :options="accountPlans"
            placeholder="TODOS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Pessoas</label>
          <v-select
            v-model="customizable.persons"
            @search="debouncedGetSearchStudent"
            multiple
            :clearable="true"
            :filterable="true"
            :reduce="(option) => option.value"
            :options="studentOptions"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Pessoas - Situação</label>
          <v-select
            v-model="customizable.student_status"
            :reduce="(option) => option.value"
            :options="[
              { label: 'ATIVO', value: true },
              { label: 'INATIVO', value: false },
              { label: 'TODAS', value: null },
            ]"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <user-search-select v-model="responsible" multiple />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Formas de Pagamento</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="customizable.typePayments"
            multiple
            :reduce="(option) => option.value"
            :options="typePayments"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Serviços</label>
          <v-select
            v-model="customizable.services"
            multiple
            :reduce="(option) => option.value"
            :options="servicePackages"
            placeholder="TODOS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Vencido/Não Vencido</label>
          <v-select
            v-model="customizable.situation2"
            :reduce="(option) => option.value"
            :options="[
              { label: 'Vencido', value: 'expired' },
              { label: 'Não Vencido', value: 'not_expired' },
            ]"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Entrada/Saída</label>
          <v-select
            v-model="customizable.income_expense"
            :reduce="(option) => option.value"
            :options="[
              { label: 'Entrada', value: 'CREDIT' },
              { label: 'Saída', value: 'DEBIT' },
            ]"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Ordem</label>
          <v-select
            v-model="customizable.order_by"
            :reduce="(option) => option.value"
            :clearable="false"
            :options="[
              { label: 'Nome', value: 'name' },
              { label: 'Nº de recibo', value: 'receipt_number' },
              { label: 'Dt. de pagamento', value: 'payday' },
              { label: 'Dt. de vencimento', value: 'expiration_date' },
              { label: 'Dt. de lançamento', value: 'data_lancamento' },
              { label: 'Tipo', value: 'type' },
              { label: 'Situação', value: 'situation' },
              { label: 'Valor da parcela', value: 'value_parcel' },
              { label: 'Valor da pago', value: 'value_pay' },
            ]"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-0">
          <label class="vs-input--label">Formato</label>
          <v-select
            v-model="customizable.format"
            :reduce="(option) => option.value"
            :clearable="false"
            :options="[
              { label: 'Retrato', value: 'pdf_retrato' },
              { label: 'Paisagem', value: 'pdf_paisagem' },
            ]"
            placeholder="TODAS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="flex items-end mt-2">
        <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium"
          >Dados Financeiros Mostrados</span
        >
      </div>
      <hr class="mb-4" />

      <div
        class="vx-row"
        v-for="(reason, i) in customizableFinancialDataList"
        :key="`${i}_reason`"
      >
        <div class="vx-col md:w-1/5 w-full" v-if="reason.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.financialFields"
                :vs-value="reason.value1"
                class="mr-4 mb-2"
                >{{ reason.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/5 w-full" v-if="reason.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.financialFields"
                :vs-value="reason.value2"
                class="mr-4 mb-2"
                >{{ reason.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/5 w-full" v-if="reason.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.financialFields"
                :vs-value="reason.value3"
                class="mr-4 mb-2"
                >{{ reason.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/5 w-full" v-if="reason.value4">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.financialFields"
                :vs-value="reason.value4"
                class="mr-4 mb-2"
                >{{ reason.text4 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/5 w-full" v-if="reason.value5">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.financialFields"
                :vs-value="reason.value5"
                class="mr-4 mb-2"
                >{{ reason.text5 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium"
          >Dados Gerais da Pessoa Mostrados</span
        >
      </div>
      <hr class="mb-4" />

      <div
        class="vx-row"
        v-for="(document, i) in personGeneralDataList"
        :key="i"
      >
        <div class="vx-col md:w-1/4 w-full" v-if="document.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.personGeneralData"
                :vs-value="document.value1"
                class="mr-4 mb-2"
                >{{ document.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full" v-if="document.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.personGeneralData"
                :vs-value="document.value2"
                class="mr-4 mb-2"
                >{{ document.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full" v-if="document.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.personGeneralData"
                :vs-value="document.value3"
                class="mr-4 mb-2"
                >{{ document.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full" v-if="document.value4">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.personGeneralData"
                :vs-value="document.value4"
                class="mr-4 mb-2"
                >{{ document.text4 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium"
          >Dados de Endereço da Pessoa Mostrados</span
        >
      </div>
      <hr class="mb-4" />

      <div
        class="vx-row"
        v-for="(document, i) in personAddressDataList"
        :key="`${i}_address`"
      >
        <div class="vx-col md:w-1/4 w-full" v-if="document.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.personAddressData"
                :vs-value="document.value1"
                class="mr-4 mb-2"
                >{{ document.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full" v-if="document.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.personAddressData"
                :vs-value="document.value2"
                class="mr-4 mb-2"
                >{{ document.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full" v-if="document.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.personAddressData"
                :vs-value="document.value3"
                class="mr-4 mb-2"
                >{{ document.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full" v-if="document.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="customizable.personAddressData"
                :vs-value="document.value4"
                class="mr-4 mb-2"
                >{{ document.text4 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupCustomizable = false"
              >Cancelar</vs-button
            >
            <vs-button class="ml-4" @click="generateReport(10)"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- PLANO DE CONTAS -->
    <vs-popup
      class="popup80"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupAccountPlan"
    >
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Plano de Contas</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="accountPlan.accountPlans"
            multiple
            :options="accountPlans"
            placeholder="TODOS"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupAccountPlan = false"
              >Cancelar</vs-button
            >
            <vs-button class="ml-4" @click="generateReport(9)"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <div class="flex-grow">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <vs-input
                class="w-full"
                type="date"
                @change="checkPeriod"
                v-model="firstPeriod"
                label="Data Inicial"
              />
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <vs-input
                class="w-full"
                type="date"
                @change="checkPeriod"
                v-model="lastPeriod"
                label="Data Final"
              />
            </div>
            <!-- <div class="vx-col md:w-1/3 w-full">
            <label class="vs-input--label">Ordem</label>
            <v-select :clearable="false" v-model="orderBy" :reduce="option => option.value"
              :options="[
                {value: 'expiration_date', label: 'Vencimento'},
                {value: 'name', label: 'Nome'},
                {value: 'type_payment', label: 'Forma de pagamento'},
                {value: 'account_plan', label: 'Plano de contas'}
              ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div> -->
          </div>
          <span class="text-danger text-sm" v-show="periodError">{{
            periodErrorMessage
          }}</span>
        </div>
        <vs-input
          label="Nome do Relatório"
          class="
            sm:mr-4
            mr-0
            sm:w-1/3
            w-full
            sm:order-normal
            order-3
            sm:mt-0
            mt-0
            ml-4
          "
          :class="{ 'mb-6': periodError }"
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Busca rápida ..."
        />
      </div>

      <h3>Relatórios</h3>
      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-table
            noDataText=""
            class="bordered"
            :data="preDocumentsFiltered"
            hoverFlat
            style="overflow: -webkit-paged-y"
          >
            <template slot="thead">
              <vs-th style="font-size: 11px"><span>TÍTULO</span></vs-th>
              <vs-th style="font-size: 11px"><span>GERAR</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                v-for="(doc, i) in data"
                :key="i"
                style="font-size: 12px; opacity: 1 !important"
              >
                <vs-td style="text-align: left"
                  ><span class="cursor-default font-semibold">{{
                    doc.title
                  }}</span></vs-td
                >
                <vs-td>
                  <div class="flex flex-wrap items-center">
                    <vx-tooltip
                      text="Relatório PDF"
                      position="top"
                      class="mt-2"
                    >
                      <svg
                        @click="print(doc, 'PDF')"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true"
                        focusable="false"
                        width="0.86em"
                        height="1em"
                        style="
                          -ms-transform: rotate(360deg);
                          -webkit-transform: rotate(360deg);
                          transform: rotate(360deg);
                        "
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1536 1792"
                      >
                        <path
                          d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z"
                        />
                      </svg>
                    </vx-tooltip>
                    <vx-tooltip
                      text="Relatório Excel"
                      position="top"
                      class="mt-2 ml-2"
                    >
                      <svg
                        @click="print(doc, 'EXCEL')"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true"
                        focusable="false"
                        width="1em"
                        height="1em"
                        style="
                          -ms-transform: rotate(360deg);
                          -webkit-transform: rotate(360deg);
                          transform: rotate(360deg);
                        "
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z"
                        />
                      </svg>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-table
            noDataText=""
            class="bordered"
            :data="preDocumentsFilteredCol2"
            hoverFlat
            style="overflow: -webkit-paged-y"
          >
            <template slot="thead">
              <vs-th style="font-size: 11px"><span>TÍTULO</span></vs-th>
              <vs-th style="font-size: 11px"><span>GERAR</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                v-for="(doc, i) in data"
                :key="i"
                style="font-size: 12px; opacity: 1 !important"
              >
                <vs-td style="text-align: left"
                  ><span class="cursor-default font-semibold">{{
                    doc.title
                  }}</span></vs-td
                >
                <vs-td>
                  <div class="flex flex-wrap items-center">
                    <vx-tooltip
                      text="Relatório PDF"
                      position="top"
                      class="mt-2"
                    >
                      <svg
                        @click="print(doc, 'PDF')"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true"
                        focusable="false"
                        width="0.86em"
                        height="1em"
                        style="
                          -ms-transform: rotate(360deg);
                          -webkit-transform: rotate(360deg);
                          transform: rotate(360deg);
                        "
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1536 1792"
                      >
                        <path
                          d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z"
                        />
                      </svg>
                    </vx-tooltip>
                    <vx-tooltip
                      text="Relatório Excel"
                      position="top"
                      class="mt-2 ml-2"
                    >
                      <svg
                        @click="print(doc, 'EXCEL')"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true"
                        focusable="false"
                        width="1em"
                        height="1em"
                        style="
                          -ms-transform: rotate(360deg);
                          -webkit-transform: rotate(360deg);
                          transform: rotate(360deg);
                        "
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z"
                        />
                      </svg>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import moduleServicePackage from '@/store/service-package/moduleServicePackage.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import SelectEmployee from '@/components/register/employee/SelectEmployee'
import UserSearchSelect from '@/components/register/user/UserSearchSelect'
import ContractEmployeeCommissionModal from '@/components/report/financial/modal/ContractEmployeeCommissionModal'
import PopupPdf from '@/components/report/pdf/PopupPdf'

export default {
  components: {
    vSelect,
    PopupPdf,
    ContractEmployeeCommissionModal,
    SelectEmployee,
    UserSearchSelect
  },

  data () {
    return {
      popupEmployee: false,
      contractEmployeeComissionPopup: false,
      employee: null,
      responsible: {
        data: null
      },
      typeReport: 'PDF',

      periodErrorMessage: 'O período é obrigatório.',
      periodError: true,
      firstPeriod: '',
      lastPeriod: '',
      orderBy: 'expiration_date',

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,
      popupReceipt: false,
      receipt: {},

      popupChargeback: false,
      chargeback: {
        account_plan_id: null,
        type_payment_id: null,
        income_expense: null,
        servicePackages: []
      },

      popupAccountPlan: false,
      accountPlan: {
        title: '',
        accountPlans: []
      },

      popupCustomizable: false,
      studentOptions: [],
      customizable: {
        title: 'Personalizável',
        for_date: 'payment_date',
        situation: null, // pago ou não pago
        situation2: null, // vencido ou não vencido
        student_status: null,
        income_expense: null, // credit debit
        order_by: 'name',
        format: 'pdf_retrato',
        accounts: [],
        accountPlans: [],
        typePayments: [],
        persons: [],
        financialFields: [],
        personGeneralData: [],
        personAddressData: []
      },
      customizableFinancialDataList: [
        {
          value1: 'account',
          text1: 'Conta',
          value2: 'interest',
          text2: 'Juros',
          value3: 'service',
          text3: 'Serviço',
          value4: 'responsible',
          text4: 'Responsável',
          value5: 'commission',
          text5: 'Comissão'
        },
        {
          value1: 'data_lancamento',
          text1: 'Lançamento',
          value2: 'fee',
          text2: 'Multa',
          value3: 'situation',
          text3: 'Situação',
          value4: 'type_payment',
          text4: 'Tipo pagamento',
          value5: 'sub_total',
          text5: 'Valor Total Serviço Contratado'
        },
        {
          value1: 'payday',
          text1: 'Dt. pagamento',
          value2: 'observation',
          text2: 'Observação',
          value3: 'type',
          text3: 'Tipo',
          value4: 'value_parcel',
          text4: 'Valor Parcela',
          value5: false,
          text5: false
        },
        {
          value1: 'expiration_date',
          text1: 'Dt. vencimento',
          value2: 'account_plan',
          text2: 'Plano de contas',
          value3: 'receipt',
          text3: 'Recibo',
          value4: 'value_pay',
          text4: 'Valor pago',
          value5: false,
          text5: false
        }
      ],

      personGeneralDataList: [
        {
          value1: 'cpf',
          text1: 'CPF',
          value2: 'mobile',
          text2: 'Fone celular',
          value3: 'phone',
          text3: 'Fone fixo',
          value4: 'rg',
          text4: 'RG'
        },
        {
          value1: 'name',
          text1: 'Nome',
          value2: 'mail',
          text2: 'E-mail',
          value3: 'extra_field',
          text3: 'Campo Extra',
          value4: false,
          text4: false,
        }
      ],

      personAddressDataList: [
        {
          value1: 'neighborhood',
          text1: 'Bairro',
          value2: 'city',
          text2: 'Cidade',
          value3: 'state',
          text3: 'Estado',
          value4: 'street',
          text4: 'Rua'
        },
        {
          value1: 'zip_code',
          text1: 'CEP',
          value2: false,
          text2: false,
          value3: false,
          text3: false,
          value4: false,
          text4: false
        }
      ],

      // CONTRATOS FOI REALOCADO PARA A ABA DE SERVIÇOS
      // popupContract: false,
      // contract: {
      //   title: '',
      //   situation_contract: null,
      //   servicePackages: []
      // },

      users: [],
      popupIndividualCashier: false,
      individualCashier: {
        title: '',
        user_id: null,
        simpleOrDetailed: ''
      },

      searchQuery: '',
      preDocumentsFiltered: [],
      preDocuments: [
        { value: 1, title: 'Boletos' },
        { value: 2, title: 'Caixa Geral' },
        // { value: 5, title: 'Contratos'    }, // foi realocado para a aba de serviços
        { value: 6, title: 'Estornos' },
        { value: 7, title: 'Meu Caixa Detalhado' },
        { value: '7B', title: 'Meu Caixa Resumido' },
        { value: 10, title: 'Personalizável' },
        { value: 12, title: 'Movimentação Mensal - Totais' },
        { method: 'pdfExcel', title: 'Renegociação de dívida' }
      ],
      preDocumentsFilteredCol2: [],
      preDocumentsCol2: [
        { value: 9, title: 'Por Plano de Contas' },
        { value: 8, title: 'Recibos' },
        { value: 11, title: 'Serviço por Funcionário' },
        { method: 'contractEmployeeComission', title: 'Comissões por data de contrato' },
        { value: 3, title: 'Total à Pagar Detalhado' },
        { value: '3B', title: 'Total à Pagar Resumido' },
        { value: 4, title: 'Total à Receber Detalhado' },
        { value: '4B', title: 'Total à Receber Resumido' }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelect']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    servicePackages () {
      return this.$store.getters['servicePackage/forSelect']
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    validateIndividualCashier () {
      return !this.errors.any('individualCashier')
    },
    params () {
      return {
        format: this.typeReport,
        startDate: this.firstPeriod,
        endDate: this.lastPeriod
      }
    }
  },
  methods: {
    pdfExcel (doc, type) {
      this.$refs.popupPdf.post({
        url: `${process.env.VUE_APP_API_URL}/report/financial/debtNegociation`,
        payload: {
          startDate: this.firstPeriod,
          endDate: this.lastPeriod,
          format: type
        }
      })
    },
    contractEmployeeComission (doc, type) {
      if (type === 'PDF') this.contractEmployeeComissionPopup = true
      else this.mixinNotify('Não habilitado no formato Excel', 'warning')
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http
          .post(`${process.env.VUE_APP_API_URL}/student/search`, {
            kw: search,
            type: null
          })
          .then(
            function (response) {
              for (const k in response.data) {
                //INCLUIR SOMENTE OS ALUNOS QUE NÃO EXISTEM
                const idx = this.studentOptions.findIndex(
                  (e) => e.value === response.data[k].id
                )
                if (idx === -1) {
                  this.studentOptions.push({
                    value: response.data[k].id,
                    label: `${response.data[k].name} ${
                      response.data[k].cpf || ''
                    }`
                  })
                }
              }
              loading(false)
            }.bind(this)
          )
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    checkPeriod () {
      if (
        moment(this.firstPeriod).year() > 1000 &&
        moment(this.lastPeriod).year() > 1000
      ) {
        const difDays = moment(this.lastPeriod).diff(
          moment(this.firstPeriod),
          'days'
        )
        if (difDays < 0) {
          this.periodError = true
          this.periodErrorMessage =
            'O período inicial deve ser anterior ao final.'
          return
        } else if (difDays > 365 * 5 + 1) {
          this.periodError = true
          this.periodErrorMessage = 'O período não pode ser maior que 5 anos.'
          return
        }
        this.periodError = false
      } else {
        this.periodError = true
        this.periodErrorMessage = 'O período é obrigatório.'
      }
    },
    async print (doc, type) {

      if (doc.method) {
        this.typeReport = type
        this[doc.method](doc, type)
        return
      }

      if (this.periodError) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Cheque o Período!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      try {
        this.type = type
        this.$vs.loading()

        if (doc.value === 1) {
          // Boletos
          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          const mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_boleto'
          )
          if (
            !this.user.roles.some((role) => role.name === 'admin') &&
            (!mod || !mod.pivot.permissions.includes('generate'))
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }

          this.showPdfTitle = 'Relatório de Boletos'
          this.generateReport(doc.value)
        } else if (doc.value === 2) {
          // Caixa Geral (CashFlow)

          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          const mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_general_cashier'
          )
          if (
            !this.user.roles.some((role) => role.name === 'admin') &&
            (!mod || !mod.pivot.permissions.includes('generate'))
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }

          this.showPdfTitle = 'Relatório de Caixa Geral'
          this.generateReport(doc.value)
        } else if (doc.value === 3 || doc.value === 4) {
          // Contas a pagar e à receber DETALHADO

          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          let mod = null
          if (doc.value === 3) mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_bills_to_pay'
          )
          else mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_bills_to_receive'
          )
          if (
            !this.user.roles.some((role) => role.name === 'admin') &&
            (!mod || !mod.pivot.permissions.includes('generate'))
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }

          this.showPdfTitle =
            doc.value === 3
              ? 'Caixa Geral Contas à Pagar Detalhado'
              : 'Caixa Geral Contas à Receber Detalhado'
          this.generateReport(doc.value)
        } else if (doc.value === '3B' || doc.value === '4B') {
          // Contas a pagar e à receber RESUMIDO

          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          let mod = null
          if (doc.value === '3B') mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_bills_to_pay'
          )
          else mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_bills_to_receive'
          )
          if (
            !this.user.roles.some((role) => role.name === 'admin') &&
            (!mod || !mod.pivot.permissions.includes('generate'))
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }

          this.showPdfTitle =
            doc.value === '3B'
              ? 'Caixa Geral Contas à Pagar Resumido'
              : 'Caixa Geral Contas à Receber Resumido'
          this.generateReport(doc.value)
          // } else if (doc.value === 5) { // Contratos   FOI REALOCADO PARA A ABA DE SERVIÇOS

          //   // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          //   const mod = this.user.permissions_user.find(a => a.module === 'spa_report_financial_contracts')
          //   if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('generate'))) {
          //     this.$vs.notify({
          //       time: 5000,
          //       title: 'Aviso',
          //       text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          //       iconPack: 'feather',
          //       icon: 'icon-alert-circle',
          //       color: 'warning',
          //       position: 'center-top'
          //     })
          //     this.$nextTick(() => { this.$vs.loading.close() })
          //     return
          //   }

          //   this.showPdfTitle = 'Relatório de Contratos'
          //   this.popupContract = true
          //   this.$nextTick(() => { this.$vs.loading.close() })
        } else if (doc.value === 6) {
          // Estornos

          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          const mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_chargeback'
          )
          if (
            !this.user.roles.some((role) => role.name === 'admin') &&
            (!mod || !mod.pivot.permissions.includes('generate'))
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }

          this.showPdfTitle = 'Relatório de Estornos'
          this.popupChargeback = true
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        } else if (doc.value === 7 || doc.value === '7B') {
          // Meu Caixa Rlatório de Cxs

          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          const mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_my_cashier'
          )
          if (
            !this.user.roles.some((role) => role.name === 'admin') &&
            (!mod || !mod.pivot.permissions.includes('generate'))
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }

          this.showPdfTitle = this.individualCashier.title =
            doc.value === 7
              ? 'Relatório de Caixa Individual Detalhado'
              : 'Relatório de Caixa Individual Resumido'
          this.individualCashier.simpleOrDetailed =
            doc.value === 7 ? 'DETAILES' : 'SIMPLE'
          this.popupIndividualCashier = true
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        } else if (doc.value === 8) {
          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          const mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_receipt'
          )
          if (
            !this.user.roles.some((role) => role.name === 'admin') &&
            (!mod || !mod.pivot.permissions.includes('generate'))
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }

          this.showPdfTitle = 'Relatório de Recibos'
          this.generateReport(doc.value)
        } else if (doc.value === 9) {
          this.accountPlan.title = this.showPdfTitle =
            'Relatório por Plano de Contas'
          this.popupAccountPlan = true
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        } else if (doc.value === 11) {
          this.accountPlan.title = this.showPdfTitle =
            'Relatório de Serviço Por Funcionáio'
          this.popupEmployee = true
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        } else if (doc.value === 12) {
          // Boletos
          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          const mod = this.user.permissions_user.find(
            (a) => a.module === 'spa_report_financial_boleto'
          )
          if (
            !this.user.roles.some((role) => role.name === 'admin') &&
            (!mod || !mod.pivot.permissions.includes('generate'))
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }

          this.showPdfTitle = 'Relatório de Boletos'
          this.generateReport(doc.value)
        } else if (doc.value === 10) {
          // Personalizável
          this.showPdfTitle = doc.title
          this.popupCustomizable = true
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        } else {
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        }
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },
    async generateReport (id) {
      this.$vs.loading()
      try {
        if (id === 1) {
          // Boletos
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/boleto/report`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: 'data_documento', //this.orderBy
              format: this.type,
              payload: {
                title: 'Relatório de Boletos'
              }
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Boletos')
          }
        } else if (id === 2) {
          // Caixa Geral (CashFlow)
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/cashFlow/report`,
            {
              initial_date: this.firstPeriod,
              final_date: this.lastPeriod,
              orderBy: this.orderBy,
              format: this.type,
              payload: {
                title: 'Relatório de Caixa Geral'
              }
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Caixa Geral')
          }
        } else if (id === 3 || id === '3B' || id === 4 || id === '4B') {
          // Contas a receber e a pagar
          const payload = {
            title: '',
            type: id === 3 || id === '3B' ? 'DEBIT' : 'CREDIT',
            simpleOrDetailed: id === 3 || id === 4 ? 'DETAILES' : 'SIMPLE'
          }

          if (id === 3) payload.title = 'Caixa Geral Contas à Pagar Detalhado'
          else if (id === '3B') payload.title = 'Caixa Geral Contas à Pagar Resumido'
          else if (id === 4) payload.title = 'Caixa Geral Contas à Receber Detalhado'
          else if (id === '4B') payload.title = 'Caixa Geral Contas à Receber Resumido'

          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/receivablePayable/report`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: this.orderBy,
              format: this.type,
              payload
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, payload.title)
          }
          // } else if (id === 5) { // Contratos  REALOCADO PARA A ABA DE SERVIÇOS
          //   const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/contract/report`, {
          //     firstPeriod: this.firstPeriod,
          //     lastPeriod: this.lastPeriod,
          //     orderBy: this.orderBy,
          //     format: this.type,
          //     payload: this.contract
          //   }, { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null })

          //   if (this.type === 'PDF') {
          //     this.srcPdf = `data:application/pdf;base64,${resp.data}`
          //     this.popupShowPdf = true
          //   } else if (this.type === 'EXCEL') {
          //     const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          //     this.makeBlobDoc(blob, 'Relatório de Estornos')
          //   }
          //   this.popupContract = false
        } else if (id === 6) {
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/chargeback/report`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: this.orderBy,
              format: this.type,
              payload: this.chargeback
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Estornos')
          }
          this.popupChargeback = false
        } else if (id === 7 || id === '7B') {
          // Meu Caixa Rlatório de Cxs
          this.individualCashier.title =
            id === 7
              ? 'Relatório de Caixa Individual Detalhado'
              : 'Relatório de Caixa Individual Resumido'

          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/myCashiers/report`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: this.orderBy,
              format: this.type,
              payload: this.individualCashier
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Recibos')
          }
        } else if (id === 8) {
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/receipt/report`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: this.orderBy,
              format: this.type,
              payload: this.receipt
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Recibos')
          }
        } else if (id === 9) {
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/accountPlan/report`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: this.orderBy,
              format: this.type,
              payload: this.accountPlan
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Recibos')
          }
        } else if (id === 10) {
          if (
            !this.customizable.financialFields.length &&
            !this.customizable.personGeneralData.length &&
            !this.customizable.personAddressData.length
          ) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Selecione ao menos um campo a ser mostrado no relatório.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => {
              this.$vs.loading.close()
            })
            return
          }
          this.customizable.responsible = this.responsible.data

          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/financial/report`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: this.orderBy,
              format: this.type,
              payload: this.customizable
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Recibos')
          }
        } else if (id === 11) {
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/osByUser/report`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: this.orderBy,
              format: this.type,
              payload: this.receipt,
              employee: this.employee
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Recibos')
          }
        } else if (id === 12) {
          // Boletos
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/report/financial/transaction/sumTransactionsPerMonth`,
            {
              firstPeriod: this.firstPeriod,
              lastPeriod: this.lastPeriod,
              orderBy: 'data_documento', //this.orderBy
              format: this.type,
              payload: {
                title: 'Relatório - Movimentação Mensal - Totais'
              }
            },
            { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
          )

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            this.makeBlobDoc(blob, 'Relatório de Boletos')
          }
        }
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    async fetchUsers () {
      const resp = await this.$http.get(
        `${process.env.VUE_APP_API_URL}/users/getAll`
      )
      for (const k in resp.data) {
        this.users.push({
          value: resp.data[k].id,
          label: `${resp.data[k].name}`
        })
      }
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    getSearchResult (searchVal) {
      this.preDocumentsFiltered = JSON.parse(
        JSON.stringify(
          this.preDocuments.filter(
            (a) => a.title.toUpperCase().indexOf(searchVal.toUpperCase()) > -1
          )
        )
      )
      this.preDocumentsFilteredCol2 = JSON.parse(
        JSON.stringify(
          this.preDocumentsCol2.filter(
            (a) => a.title.toUpperCase().indexOf(searchVal.toUpperCase()) > -1
          )
        )
      )
    }
  },
  created () {
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)

    this.fetchUsers()

    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }
    this.$store
      .dispatch('servicePackage/fetchAll')
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })

    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    this.$store
      .dispatch('account/fetchAll')
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })

    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }
    this.$store.dispatch('accountPlan/fetchAll')

    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    this.$store.dispatch('typePayment/fetchAll')

    this.preDocumentsFiltered = JSON.parse(JSON.stringify(this.preDocuments))
    this.preDocumentsFilteredCol2 = JSON.parse(
      JSON.stringify(this.preDocumentsCol2)
    )
  },
  mounted () {
    this.firstPeriod = this.mixinToday()
    this.lastPeriod = this.mixinToday()
    this.$validator.validateAll()
    this.checkPeriod()
  }
}
</script>

<style lang="scss">
.con-vs-popup .vs-popup {
  width: 100%;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup50 .vs-popup {
  width: 50% !important;
}
.popup60 .vs-popup {
  width: 60% !important;
}
.vue_select_drop_size_90 .vs__dropdown-menu {
  max-height: 90px;
}
.vue_select_drop_size_160 .vs__dropdown-menu {
  max-height: 160px;
}
#student-documents th {
  background-color: #b4b4b4;
}
#student-documents th .vs-table-text {
  color: #158482;
  cursor: default;
}
#student-documents .vs-con-table .vs-con-tbody {
  border: 1px solid #c0c0c0;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#student-documents td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#student-documents td:hover {
  border-bottom: 1px solid #353434;
}
#student-documents td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#student-documents .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#student-documents .vs-table--thead {
  border: 1px solid transparent;
}
// .icon {
//   font-size: 16px;
// }
#student-documents
  .vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td {
  padding: 6px;
}
#student-documents .ps__scrollbar-y-rail {
  z-index: 99999;
}
.vs-tooltip {
  z-index: 99999;
}
.under:hover {
  text-decoration: underline;
}
</style>
